import React from "react";
import { Link } from "react-router-dom";
import Navbar from '../components/Navbar';

const Home = () => {
  return (
    <div className="bg-gray-100 text-gray-800 font-sans">
      {/* Header */}
      <Navbar /> 

      {/* Main Content */}
      <main className="container mx-auto p-8">
        {/* Welcome Section */}
        <section className="text-center">
          <h1 className="text-5xl font-bold text-blue-700 mb-4">
            Welcome to Eazy Lease
          </h1>
          <p className="text-xl text-gray-600 mb-12">
            Effortless and efficient rental management, designed for you.
          </p>
        </section>

        {/* Key Features */}
        <section className="bg-white p-10 rounded-lg shadow-lg mb-10 text-center">
          <h2 className="text-3xl font-semibold text-gray-800 mb-6">
            Key Features
          </h2>
          <div className="grid md:grid-cols-3 gap-6 text-left">
            <div className="p-4 bg-blue-50 rounded-lg shadow-sm">
              <h3 className="text-xl font-semibold text-blue-700 mb-2">
                Tenant Registration
              </h3>
              <p className="text-gray-600">
                Streamline tenant registration with a user-friendly onboarding
                process.
              </p>
            </div>
            <div className="p-4 bg-blue-50 rounded-lg shadow-sm">
              <h3 className="text-xl font-semibold text-blue-700 mb-2">
                Automated Payments
              </h3>
              <p className="text-gray-600">
                Track rent payments automatically, saving you time and effort.
              </p>
            </div>
            <div className="p-4 bg-blue-50 rounded-lg shadow-sm">
              <h3 className="text-xl font-semibold text-blue-700 mb-2">
                Lease Management
              </h3>
              <p className="text-gray-600">
                Manage leases with ease, including renewals and updates.
              </p>
            </div>
          </div>
        </section>

        {/* Get Started Button */}
        <section className="text-center">
          <Link
            to="/register"
            className="inline-block bg-blue-600 text-white text-lg py-3 px-8 rounded-md shadow-md hover:bg-blue-700 transition duration-300"
          >
            Get Started
          </Link>
        </section>
      </main>

      {/* Footer */}
      <footer className="bg-gray-800 text-gray-200 py-6 mt-12">
        <div className="container mx-auto text-center text-sm">
          <p>&copy; 2024 Eazy Lease. All rights reserved.</p>
          <p>Made with 💙 for easier rental management.</p>
        </div>
      </footer>
    </div>
  );
};

export default Home;
