import React from 'react';

const AddProperty: React.FC = () => {
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Add logic for token if necessary
    // const token = localStorage.getItem('accessToken');
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label htmlFor="propertyName">Property Name:</label>
        <input type="text" id="propertyName" name="propertyName" required />
      </div>
      <button type="submit">Add Property</button>
    </form>
  );
};

export default AddProperty;
