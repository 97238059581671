import React from 'react';
import { Link } from 'react-router-dom';

const Navbar: React.FC = () => {
  return (
    <header className="bg-blue-700 text-white py-6 shadow-md">
      <nav className="container mx-auto flex justify-center space-x-12 text-lg font-semibold">
        <Link to="/" className="hover:underline">
          Home
        </Link>
        <Link to="/register" className="hover:underline">
          Register
        </Link>
        <Link to="/login" className="hover:underline">
          Login
        </Link>
      </nav>
    </header>
  );
};

export default Navbar;