import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const UpdateProperty: React.FC = () => {
  const { propertyId } = useParams<{ propertyId: string }>();
  const [property, setProperty] = useState<{ address: string; description: string } | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProperty = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/properties/${propertyId}`);
        if (response.ok) {
          const data = await response.json();
          setProperty(data);
        } else {
          console.error("Failed to fetch property");
        }
      } catch (error) {
        console.error("Error fetching property:", error);
      }
    };

    fetchProperty();
  }, [propertyId]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const token = localStorage.getItem('accessToken');

    try {
      const formData = new FormData(e.currentTarget);
      const propertyDetails = {
        address: formData.get("address") as string,
        description: formData.get("description") as string,
      };

      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/properties/${propertyId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(propertyDetails),
      });

      if (response.ok) {
        const updatedProperty = await response.json();
        console.log("Property updated:", updatedProperty);
        navigate("/properties");
      } else {
        console.error("Failed to update property");
      }
    } catch (error) {
      console.error("Error updating property:", error);
    }
  };

  return property ? (
    <form onSubmit={handleSubmit}>
      <div>
        <label htmlFor="address">Address:</label>
        <input
          type="text"
          id="address"
          name="address"
          defaultValue={property.address}
          required
        />
      </div>
      <div>
        <label htmlFor="description">Description:</label>
        <textarea
          id="description"
          name="description"
          defaultValue={property.description}
          required
        ></textarea>
      </div>
      <button type="submit">Update Property</button>
    </form>
  ) : (
    <p>Loading property details...</p>
  );
};

export default UpdateProperty;
