import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface Property {
  property_id: string; // Adjust type as needed (e.g., number if IDs are numeric)
  address: string;
  description: string;
}

const PropertyList: React.FC = () => {
  const [properties, setProperties] = useState<Property[]>([]); // Define the state type
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/properties`);
        if (response.ok) {
          const data: Property[] = await response.json(); // Ensure the fetched data matches the Property type
          setProperties(data);
        } else {
          console.error('Failed to fetch properties');
        }
      } catch (error) {
        console.error('Error fetching properties:', error);
      }
    };

    fetchProperties();
  }, []);

  const handleDelete = async (propertyId: string) => {
    try {
      await fetch(`${process.env.REACT_APP_API_BASE_URL}/properties/${propertyId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      setProperties(properties.filter((property) => property.property_id !== propertyId));
    } catch (error) {
      console.error('Error deleting property:', error);
    }
  };

  return (
    <div>
      <h1>Properties</h1>
      <table className="table-auto">
        <thead>
          <tr>
            <th>Address</th>
            <th>Description</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {properties.map((property) => (
            <tr key={property.property_id}>
              <td className="py-2">{property.address}</td>
              <td className="py-2">{property.description}</td>
              <td className="py-2">
                <button
                  onClick={() => navigate(`/update-property/${property.property_id}`)}
                  className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-1 px-2 rounded mr-2"
                >
                  Update
                </button>
                <button
                  onClick={() => handleDelete(property.property_id)}
                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PropertyList;
