import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import Register from './pages/Register';
import Dashboard from './pages/Dashboard';
import AddProperty from './pages/AddProperty';
import UpdateProperty from './pages/UpdateProperty';
import PropertyList from './pages/PropertyList';

function App() {
  return (
    <div className="min-h-screen bg-gray-100 text-gray-800">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/add-property" element={<AddProperty />} />
          <Route path="/update-property/:propertyId" element={<UpdateProperty />} />
          <Route path="/property-list" element={<PropertyList />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
